<template>
  <div id="app" :style="`${background}`">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      background: '',
    }
  },
  created() {
    var background = this.getUrlParameter('background');
    if (background !== '') {
      this.background = `background-color: #${background};`
    }
  },
  methods: {
    getUrlParameter(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search || window.location.href);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
  },
}
</script>

<style>
</style>
